import axios from 'axios'
import Store from '../store'
import Router from '../router/lk-router'
import { app as Vue } from '@/entry-files/lk'
// import Session from '../Plugins/Session/Session'

import { SRPClientSession } from '@/modules/SRPClientSession.js'
import { UIAuthForm } from '@/models/UIAuthForm'
import { UserPublicKey } from '@/models/UserPublicKey'
import sha256 from 'js-sha256'
import { getListCurrency } from '@/Methods/getAllCurrencyList'
import { PushNotifications } from '@capacitor/push-notifications'
import FbToken from '@/Plugins/FbToken'
import { KYC_STATUS_NAMES } from '@/constants/admin'

require('@/modules/crypt.min.js')

// Vue.use(Session)

let flagRegistration = false

function hexToBytes (hex) {
  let bytes = []
  let c = 0
  for (; c < hex.length; c += 2) {
    bytes.push(parseInt(hex.substr(c, 2), 16))
  }
  return bytes
}

const getNG = () => {
  return axios({
    method: 'GET',
    url: `${Store.state.backEndAddress}/auth/srp/parameters`, // здесь урл пост запроса
    headers: {
      'Content-type': 'application/x-www-form-urlencoded'
    }
  })
    .then(response => {
      if (response.data.status === 200) {
        Store.commit('updateSRP6CryptoParamsN', response.data.payload.n)
        Store.commit('updateSRP6CryptoParamsG', response.data.payload.g)
      }
    })
    .then(() => {
      return true
    })
}

// const lastStepLogin = (userID) => {
//   return axios({
//     method: 'GET',
//     url: `${Store.state.backEndAddress}/currencies`
//   })
//     .then(response => {
//       getListCurrency(response.data.payload)
//       localStorage.setItem('user_id', userID)
//       Store.commit('SetAuthorized', true)
//       Store.commit('SetUserName', userID)
//       localStorage.setItem('authorized', 'true')
//       if (flagRegistration) {
//         Router.push('/lk/successRegistration')
//       } else {
//         Router.push('/lk/myAccount')
//       }
//     })
// }

// const loginFunction = (dataForSend) => {
//
// }

const state = () => ({
  meData: {}
})

const mutations = {
  setMeData (state, data) {
    state.meData = data
  }
}

const actions = {
  doPushSubscribe (context) {

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        // alert('GRANTED NOTIFICATIONS')
        PushNotifications.register();
      } else {
        // Show some error
        // alert('NO NOTIFICATIONS')
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token) => {
       // alert('REGISTERED ' + token?.value)
        if (token?.value) {
          return axios({
            method: 'POST',
            url: `${Store.state.backEndAddress}/user/customer/fcm-token/${token?.value}`
          })
        }
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error) => {
        // alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification) => {
        // alert('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification) => {
        // alert('Push action performed: ' + JSON.stringify(notification));
      }
    );
  },
  /**
   * Функция логина
   * @param {*} context
   * @param {*} dataForSend
   */
  singIn (context, dataForSend) {
    return new Promise((resolve, reject) => {
      getNG()
        .then((response) => {
          const srpClient = new SRPClientSession()
          // eslint-disable-next-line
          let cryptService = new CryptService()
          if (response) {
            return axios({
              method: 'GET',
              url: `${Store.state.backEndAddress}/auth/challenge`, // здесь урл пост запроса
              params: {
                username: dataForSend.login
              }
            })
              .then(response => {
                if (response.data.status === 200) {
                  return response
                }
                throw Error()
              })
              .then(response => {
                Store.commit('updateAuthForm2Client', response.data.payload)
                let authForm2Server = srpClient.stage1Login(dataForSend.login, dataForSend.password, Store.getters.getAuthForm2Client.s)
                srpClient.stage2(Store.getters.getAuthForm2Client.B)
                authForm2Server.a = srpClient.getA().toString(16)
                let m1 = srpClient.getM1().toString(16)
                // eslint-disable-next-line
                let keys = cryptService.importKeys(dataForSend.key)
                // eslint-disable-next-line
                let signature = cryptService.sign(keys, hexToBytes(Store.getters.getAuthForm2Client.digest))
                let uiAuthForm = new UIAuthForm(authForm2Server, m1, signature)
                let formData = 'username=' + uiAuthForm.authForm2Server.l +
                  '&data=' + btoa(JSON.stringify(uiAuthForm))
                return formData
              })
              .then(formData => {
                return axios({
                  method: 'POST',
                  url: `${Store.state.backEndAddress}/auth/sign-in`,
                  data: formData,
                  contentType: 'application/x-www-form-urlencoded; charset=utf-8'
                })
                  .then(response => {
                    let userId
                    if (response?.data.status === 200) {
                      const { principal } = response.data.payload
                      const roles = principal?.granted_authorities
                      userId = principal?.login
                      localStorage.setItem('UserRoles', JSON.stringify(roles))
                      Vue.sessionStart(response.data.payload.timeout)
                      if (isAndroidApp) {
                        FbToken.addEventListener('needSendToken', (token) => {
                          axios({
                            method: 'POST',
                            url: `${Store.state.backEndAddress}/user/customer/fcm-token/${token}`
                          })
                        })
                        context.dispatch('doPushSubscribe')
                      }
                      return axios({
                        method: 'GET',
                        url: `${Store.state.backEndAddress}/wallets`
                      })
                        .then(response => {
                          if (response.data.status === 200) {
                            if (response.data.payload.wallet_list) {
                              flagRegistration = false
                              context.dispatch('lastStepLogin', userId)
                              resolve()
                            } else {
                              dataForSend.login = userId
                              Store.dispatch('createWallet', dataForSend)
                                .then(response => {
                                  if (response.data.status === 200) {
                                    flagRegistration = true
                                    context.dispatch('lastStepLogin', userId)
                                  } else {
                                    reject(new Error('err'))
                                  }
                                })
                            }
                          } else {
                            reject(new Error('err'))
                          }
                        })
                    } else {
                      reject(new Error('err'))
                    }
                  })
                  .catch(err => {
                    console.log('ERROR', err)
                    reject(new Error(err))
                  })
              })
              .catch(err => {
                reject(new Error(err))
              })
          }
        })
        .catch(err => {
          reject(new Error(err))
        })
    })
  },
  sendEmail (context, dataForSend) {
    return axios({
      method: 'POST',
      url: `${Store.state.backEndAddress}/auth/email/confirm`,
      data: dataForSend,
      contentType: 'application/json; charset=utf-8'
    })
      .then(response => {
        const { data } = response
        if (data?.status !== 200) {
          Promise.reject(data?.message)
        }
        return response
      })
  },
  signUp (context, dataForSend) {
    return new Promise((resolve, reject) => {
      getNG()
        .then(response => {
          if (response) {
            const clientSession = new SRPClientSession()
            // eslint-disable-next-line
            let cryptService = new CryptService()
            const user = clientSession.register(dataForSend.userId, dataForSend.password, dataForSend.code)
            let keys = cryptService.generateKeys()
            let publicPEM = cryptService.exportPublic(keys)
            let privatePEM = cryptService.exportPrivate(keys)
            let msgHash = sha256.array(publicPEM)
            let signature = cryptService.sign(keys, msgHash)
            user.user_public_key = new UserPublicKey('web-app', publicPEM, signature)
            return axios({
              method: 'POST',
              url: `${Store.state.backEndAddressNotLink}/sign-up`,
              data: user,
              contentType: 'application/json; charset=utf-8'
            })
              .then(response => {
                if (response.data.status === 200) {
                  return privatePEM
                } else {
                  return false
                }
              })
              .catch(err => reject(err))
          }
        })
        .then(key => {
          if (key) {
            // eslint-disable-next-line
            resolve(key)
          } else {
            // eslint-disable-next-line
            resolve(key)
          }
        })
    })
  },
  logOut (context) {
    return new Promise(function (resolve, reject) {
      Vue.finishSession()
      resolve(true)
    })
  },
  signOut (context) {
    return axios({
      method: 'POST',
      url: `${Store.state.backEndAddress}/auth/sign-out`
    })
      .then(response => {
        let thisLang = localStorage.getItem('locale')
        let cookieFlag = localStorage.getItem('cookieFlag')
        // localStorage.clear()
        localStorage.setItem('locale', thisLang)
        localStorage.setItem('authorized', 'false')
        localStorage.setItem('cookieFlag', cookieFlag)
        Vue.finishSession()
        Store.commit('SetUserName', '')
        Store.commit('SetAuthorized', false)
        Store.commit('SetLoaderFlag', false)
        return true
      })
      .catch(response => {
        return false
      })
  },
  async getMe ({ dispatch, commit }) {
    const userId = localStorage.getItem('user_id')
    const data = await dispatch('fetchUser', userId)
    commit('setMeData', data || {})
  },
  lastStepLogin (context, userID) {
    return axios({
      method: 'GET',
      url: `${Store.state.backEndAddress}/currencies`
    })
      .then(response => {
        getListCurrency(response.data.payload)
        localStorage.setItem('user_id', userID)
        Store.commit('SetAuthorized', true)
        Store.commit('SetUserName', userID)
        localStorage.setItem('authorized', 'true')
        if (flagRegistration) {
          Router.push('/lk/successRegistration')
        } else {
          Router.push('/lk/myAccount')
        }
      })
  }
}

const getters = {
  kycVerified: state => state.meData?.kyc_status === KYC_STATUS_NAMES.VERIFIED
}

export default {
  state,
  mutations,
  actions,
  getters
}
